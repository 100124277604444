import React from "react";
import PropTypes from "prop-types";

const primary = "border-wbBlue text-wbBlue hover:bg-wbBlue hover:text-wbSand";
const secondary = "border-white text-white hover:bg-white hover:text-wbBlue";
const Button = ({ className, variant = "secondary", href, ...props }) => {
  const button = (
    <button
      className={`${
        variant === "secondary" ? secondary : primary
      } border-1 rounded p-2 shadow-sm transition transform duration-300 ${className} text-meta`}
      {...props}
    />
  );

  if (href) {
    return (
      <a href={href} className={className} {...props}>
        {button}
      </a>
    );
  }
  return button;
};

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

export default Button;
