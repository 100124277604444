import * as React from "react";
import Helmet from "react-helmet";
import { A1, A2, A7 } from "../components/fonts";
import Container from "../components/container";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/button";

const VimeoVideo = (props) => {
  return (
    <div className="relative pt-vimeo">
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={`//player.vimeo.com/video/${props.identifier}?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;color=ff5900&amp;background=1`}
        frameBorder="0"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowFullScreen=""
      ></iframe>
    </div>
  );
};

VimeoVideo.propTypes = {
  identifier: PropTypes.string,
};

const Divider = () => (
  <div className="col-span-30 md:col-start-4 md:col-span-22 my-12 border-wbStone border-t-1 w-full h-[1px]"></div>
);

const FeatureText = ({ children }) => (
  <div className="col-span-30 flex flex-row flex-wrap justify-between gap-x-8 gap-y-8">
    {children}
  </div>
);
FeatureText.propTypes = {
  children: PropTypes.node,
};
const FeatureDescription = ({ children }) => (
  <div className="flex-1 flex flex-col gap-4 max-w-xl min-w-[300px]">
    {children}
  </div>
);
FeatureDescription.propTypes = {
  children: PropTypes.node,
};

const FeatureScreen = ({ children }) => (
  <div className="col-span-30 sm:col-span-19 sm:col-start-12 md:col-span-15 md:col-start-16 text-center">
    {children}
  </div>
);
FeatureScreen.propTypes = {
  children: PropTypes.node,
};

const PlatformPage = () => {
  return (
    <>
      <Helmet title="Platform" />
      <Container className="px-8 mt-20 text-black">
        <div
          className="col-span-30 w-full md:h-[850px] sm:h-[300px] h-[260px] bg-wbStone50 bg-macbook-hero md:bg-fixed bg-no-repeat bg-cover bg-center rounded-2xl
        flex flex-row items-center
        pl-0
        sm:pl-8
        md:pl-16
        mb-20
        sm:mb-12
        md:mb-6
        "
        >
          <div className="text-white text-center sm:text-left flex flex-col gap-y-8 flex-1">
            <A1 className="break-all">
              Goodbye,
              <br />
              spreadsheets.
            </A1>
            <Button
              href="https://www.loom.com/share/e5e994f94d5e490a9fd24e0367fad2f3"
              target="_blank"
              rel="noreferrer"
            >
              Watch a demo
            </Button>
          </div>
          {/* <div className="bg-macbook-hero bg-cover h-80 col-span-30 sticky top-8">test */}
          {/* <div className="top-0 sticky bg-macbook-hero">sticky</div> */}
          {/* </div> */}
        </div>

        <FeatureText>
          <FeatureDescription>
            <A2>
              Fulfillment
              <br />
              Progress
            </A2>
            <p className="">
              Track progress for your critical raw materials. View your order
              quantity, shipment and delivery status all in one dashboard.
            </p>
            <div>
              <Button variant="primary" href="/request-demo" className="">
                Book a demo
              </Button>
            </div>
          </FeatureDescription>

          <StaticImage
            src="../images/mockups/fulfillment.png"
            className="max-w-[629px]"
            imgClassName="animate-fade-in animate-blur-in"
          />
        </FeatureText>

        <Divider />

        <FeatureText>
          <FeatureDescription>
            <A2>Live Tracking</A2>
            <p>
              Waybridge pulls data directly from leading real time visibility
              providers like Project44, Marine Traffic and Railinc to give
              accurate and up to date information on where your material is on
              its journey.
            </p>
            <div>
              <Button variant="primary" href="/request-demo">
                Book a demo
              </Button>
            </div>
          </FeatureDescription>

          <StaticImage
            src="../images/mockups/tracking.png"
            className="max-w-[629px]"
            imgClassName="animate-fade-in animate-blur-in"
          />
        </FeatureText>

        <Divider />

        <FeatureText>
          <FeatureDescription>
            <A2 title="Carbon dioxide equivalent">CO2e</A2>
            <p>
              Take the guesswork out of your carbon footprint and start to
              optimize your decision making. Waybridge calculates Scope 3
              transportation emissions for all of your shipments.
            </p>
            <div>
              <Button
                variant="primary"
                href="/documents/Co2e-Waybridge.pdf"
                download
              >
                Download PDF
              </Button>
            </div>
          </FeatureDescription>

          <StaticImage
            src="../images/mockups/co2e_2x.png"
            className="max-w-[629px]"
            imgClassName="animate-fade-in animate-blur-in"
          />
        </FeatureText>

        <Divider />

        <FeatureText>
          <FeatureDescription>
            <A2>Inventory Forecasting</A2>
            <p>
              Never run out of (or hold too much) inventory by optimizing
              balances with Waybridge. We take data from your ecosystem to
              better project material balances in the future.
            </p>
            <div>
              <Button variant="primary" href="/request-demo">
                Book a demo
              </Button>
            </div>
          </FeatureDescription>

          <StaticImage
            src="../images/mockups/forecasting.png"
            className="max-w-[629px]"
            imgClassName="animate-fade-in animate-blur-in"
          />
        </FeatureText>

        <Divider />

        <FeatureText>
          <FeatureDescription>
            <A2>Secure Collaborative Platform</A2>
            <p>
              Connect digitally and improve your working relationship with your
              counterparties. We automate your shipment input flows to minimize
              manual entry and errors.{" "}
            </p>
            <div>
              <Button variant="primary" href="/request-demo">
                Book a demo
              </Button>
            </div>
          </FeatureDescription>

          <StaticImage
            src="../images/mockups/quantity.png"
            className="max-w-[629px]"
            imgClassName="animate-fade-in animate-blur-in"
          />
        </FeatureText>

        <Divider />

        <div className="col-span-30">
          <div className="w-8/12 py-16 pt-0 md:pt-16 mx-auto">
            <div className="grid my-24 mt-0 md:mt-24">
              <A1 className="text-center mb-12">
                The highest standard
                <br /> in data security.
              </A1>
              <A7 className="text-center max-w-sm mx-auto">
                Waybridge has completed the{" "}
                <span className="text-wbBlue">
                  System and Organization Controls (SOC 2) Type 2 certification
                </span>
                , the highest industry standard for data security.
              </A7>
              <A7 className="text-center max-w-sm mx-auto pt-5 mb-4">
                Best-in-class privacy, security, uptime, processing integrity,
                and confidentiality.
              </A7>
              <Button
                variant="primary"
                href="/press-releases/soc-2"
                className="mx-auto"
              >
                Read more
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default PlatformPage;
